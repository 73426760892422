/* .owl-stage {
  width: 100% !important;
  width: auto !important;
} */

div.dt-container .dt-paging .dt-paging-button{
  padding: 0;
  border: none !important;
  background-color:transparent !important;
  background: transparent !important;
}
